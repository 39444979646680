import loadable from "@loadable/component";
import { inject } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import type { BlockFragment } from "@ihr-radioedit/inferno-webapi";
import { reverseRoute } from "@inferno/renderer-shared-core";
import { MagicLink } from "../../ui";
import { ForecastItem } from "../../services/Weather";
import { ArrowIcon } from "../icons/ArrowIcon.component";
import "./WeatherTile.style.scss";
import type { Store } from "@inferno/renderer-shared-core";

const WeatherIcon = loadable(() => import("../../components/icons/WeatherIcon.component"), { ssr: false });

interface WeatherProps {
  block: BlockFragment;
  store?: Store;
  forecast?: ForecastItem[];
}
interface SevenDayProps {
  weather_market?: string | null;
  forecast?: ForecastItem[];
}
export const SevenDay = ({ forecast }: SevenDayProps) => {
  const { t } = useTranslation();
  if (!forecast) {
    return null;
  }

  return (
    <section className="seven-day-forecast">
      <div className="forecast-info">
        {forecast.map((item, i) => (
          <figure key={i}>
            <header>{t(item.dayOfWeek?.substr(0, 3) || "")}</header>
            <section className="weather-conditions-icon">
              <WeatherIcon iconCode={item.day?.iconCode || item.night?.iconCode || 44} />
            </section>
            <figcaption>
              <p>
                <span className="temperature">
                  {t("Lo")} {item.temperatureMin}°
                </span>
                <span className="seperator">|</span>
                <span className="temperature">
                  {t("Hi")} {item.temperatureMax}°
                </span>
              </p>
            </figcaption>
          </figure>
        ))}
      </div>
    </section>
  );
};

export const WeatherTile = inject("store")(({ store, block, forecast }: WeatherProps) => {
  if (!store) {
    return null;
  }

  const { site } = store;
  const { t } = useTranslation();
  const weather_market = site.sections.general?.weather_market;
  const url = reverseRoute(site, "weather") || "#";
  useEffect(() => {
    store.storeBlock(block);
  });

  return (
    <>
      <section className="weather-tile">
        <nav className="tile-container">
          <MagicLink className="weather-market" to={url}>
            <span className="location">{weather_market}</span>
            <span className="title">{t("weather")}</span>
          </MagicLink>
          <MagicLink aria-label="Arrow icon" className="conditions-icon" to={url}>
            <ArrowIcon />
          </MagicLink>
        </nav>
        <MagicLink className="weather-forecast" to={url}>
          <SevenDay forecast={forecast} />
        </MagicLink>
      </section>
    </>
  );
});

export default WeatherTile;
